export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/ucenter/user',
      method: 'POST'
    },
    {
      path: '/ucenter/user/auth/{userId}',
      method: 'PUT'
    },
    {
      path: '/ucenter/org/all_tree',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'PUT'
    },
    {
      path: '/ucenter/user/auth/{userId}',
      method: 'PUT'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all_tree',
      method: 'GET'
    }]
  },
  sort: {
    funKey: 'sort',
    name: '默认排序设置',
    apis: [{
      path: '/ucenter/user/sort/{userId}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'DELETE'
    }]
  },
  reset_pwd: {
    funKey: 'reset_pwd',
    name: '重置密码',
    apis: [{
      path: '/ucenter/user/{userId}/password/default',
      method: 'PUT'
    }]
  }
}