import org from './org'
import orgType from './orgType'
import placard from './placard'
import userGroup from './userGroup'
import fun from './fun'
import menu from './menu'
import role from './role'
import user from './user'
import orgInfo from './org_info'
import orgDw from './org_dw'

export default [
{
  routerNames: ['sys.user'],
  funs: user
},
{
  routerNames: ['config.user_group'],
  funs: userGroup
},
{
  routerNames: ['sys.role'],
  funs: role
},
{
  routerNames: ['sys.menu'],
  funs: menu
},
{
  routerNames: ['sys.fun'],
  funs: fun
},
{
  routerNames: ['sys.placard'],
  funs: placard
},
{
  routerNames: ['org.type'],
  funs: orgType
},
{
  routerNames: ['org.manage', 'org.ls', 'org.gl'],
  funs: org
},
{
  routerNames: ['org.dw'],
  funs: orgDw
},
{
  routerNames: ['org.info'],
  funs: orgInfo
},
{
  routerNames: ['org.position'],
  funs: role
}]